export const eventsData = [
  [
    {
      name: "Dodoco's Boom-Bastic Escapades",
      pos: '0% 40%',
      image: 'Dodocos Boom-Bastic Escapades.png',
      start: '2024-09-09 06:00:00',
      end: '2024-09-23 03:59:59',
      color: '#fef7e4',
      zoom: '250%',
      url: 'https://www.hoyolab.com/article/33001020',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Traces of Artistry',
      pos: '80% 40%',
      image: 'Traces of Artistry.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-09-16 03:59:59',
      color: '#f4e9a7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32519656',
      showOnHome: true,
    },
    {
      name: 'Ley Line Overflow',
      pos: '0% 20%',
      image: 'leyline_overflow.jpg',
      start: '2024-09-16 04:00:00',
      end: '2024-09-23 03:59:59',
      color: '#5baced',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/33249105',
      showOnHome: true,
    },
    {
      name: 'Mementos of Teyvat',
      pos: '0% 20%',
      image: 'Mementos of Teyvat tmp.png',
      start: '2024-09-28 06:00:00',
      end: '2024-10-08 03:59:59',
      color: '#5489ff',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/33001020',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Brilliant Dawn - Daily Login',
      pos: '0% 50%',
      image: 'Brilliant Dawn.png',
      start: '2024-08-30 04:00:00',
      end: '2024-09-18 03:59:59',
      color: '#ff8d9e',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32554889',
      showOnHome: true,
    },
    {
      name: 'Of Thorns and Crowns',
      pos: '0% 50%',
      image: 'Of Thorns and Crowns.png',
      start: '2024-09-20 10:00:00',
      end: '2024-09-30 03:59:59',
      color: '#f0954d',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/33358228',
      showOnHome: true,
    },
    {
      name: 'Reminiscent Regimen: Thrill',
      pos: '0% 25%',
      image: 'Reminiscent Regimen Thrill.png',
      start: '2024-10-17 10:00:00',
      end: '2024-10-28 03:59:59',
      color: '#dddbb0',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/34259331',
      showOnHome: true,
    },
    {
      name: 'Feast of Pursuit',
      pos: '0% 20%',
      image: 'Feast of Pursuit tmp.png',
      start: '2024-11-01 10:00:00',
      end: '2024-11-15 03:59:59',
      color: '#d5b3d1',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/34470471',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Where the Springs Return',
      pos: '0% 20%',
      image: 'Where the Springs Return.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:59',
      color: '#a0bbef',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
    {
      name: "Yupanqui's Turnfire",
      pos: '0% 20%',
      image: 'Yupanquis Turnfire.jpg',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:59',
      color: '#a8d3e7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
    {
      name: 'Aphid Treasure Trace',
      pos: '0% 25%',
      image: 'Aphid Treasure Trace.png',
      start: '2024-10-10 10:00:00',
      end: '2024-10-21 03:59:59',
      color: '#5792f7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/34043094',
      showOnHome: true,
    },
    {
      name: 'Chromatic Ode of Candies and Roses',
      pos: '30% 15%',
      image: 'Chromatic Ode of Candies and Roses.png',
      start: '2024-10-23 10:00:00',
      end: '2024-11-07 03:59:59',
      color: '#e4fce2',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/34402598',
      showOnHome: true,
    },
    {
      name: 'Marvelous Merchandise',
      pos: '0% 80%',
      image: 'marvelous_merchandise_event.jpg',
      start: '2024-11-11 10:00:00',
      end: '2024-11-18 03:59:59',
      color: '#fad688',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/34470471',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Flowers Resplendent on the Sun-Scorched Sojourn',
      pos: '0% 25%',
      image: 'Flowers Resplendent on the Sun-Scorched Sojourn.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-10-08 14:59:59',
      color: '#ea7745',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
    {
      name: 'The Rainbow Destined to Burn',
      pos: '0% 10%',
      image: 'The Rainbow Destined to Burn.png',
      start: '2024-10-09 06:00:00',
      end: '2024-11-19 14:59:59',
      color: '#58a8ea',
      zoom: '150%',
      url: 'https://www.hoyolab.com/article/34010544',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Sharktacular Surfari - Mualani Banner',
      pos: '20% 25%',
      zoom: '200%',
      image: 'Sharktacular Surfari 1.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#6fc5e2',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Seeker of Flame-Wrought Secrets - Kinich Banner',
      pos: '20% 25%',
      zoom: '200%',
      image: 'Seeker of Flame-Wrought Secrets 1.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#53dcb9',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
    {
      name: "Forgefire's Blessing - Xilonen Banner",
      pos: '40% 25%',
      zoom: '200%',
      image: 'Forgefires Blessing 1.png',
      start: '2024-10-09 06:00:00',
      end: '2024-10-29 17:59:00',
      color: '#fae3a4',
      url: 'https://www.hoyolab.com/article/34010550',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: "The Moongrass' Enlightenment - Nahida Banner",
      pos: '30% 15%',
      zoom: '200%',
      image: 'The Moongrass Enlightenment 4.png',
      start: '2024-10-29 18:00:00',
      end: '2024-11-19 14:59:00',
      color: '#b8db94',
      url: 'https://www.hoyolab.com/article/34470331',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Leaves in the Wind - Kazuha Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Leaves in the Wind 5.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#8FFFDE',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Reign of Serenity - Raiden Shogun Banner',
      pos: '20% 18%',
      zoom: '200%',
      image: 'Reign of Serenity 5.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#D0AEF2',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
    {
      name: 'Of Silken Clouds Woven - Chiori Banner',
      pos: '15% 15%',
      zoom: '200%',
      image: 'Of Silken Clouds Woven 2.png',
      start: '2024-10-09 06:00:00',
      end: '2024-10-29 17:59:00',
      color: '#ffbd34',
      url: 'https://www.hoyolab.com/article/34010550',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Moment of Bloom - Hu Tao Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'Moment of Bloom 5.png',
      start: '2024-10-29 18:00:00',
      end: '2024-11-19 14:59:00',
      color: '#c66051',
      url: 'https://www.hoyolab.com/article/34470331',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 69.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 70.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 71.png',
      start: '2024-10-09 06:00:00',
      end: '2024-10-29 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/34010550',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 72.png',
      start: '2024-10-29 18:00:00',
      end: '2024-11-19 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/34470331',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-08-16 04:00:00',
      end: '2024-09-16 04:00:00',
      color: '#4299E1',
      description:
        'When characters attack Burning opponents, their CRIT Rate is increased by 15% and their CRIT DMG is increased by 30%.',
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-09-16 04:00:00',
      end: '2024-10-16 04:00:00',
      color: '#4299E1',
      description:
        "After the active character uses an Elemental Skill, Normal Attack and Elemental Skill DMG is increased by 40% for 12s. For characters in Nightsoul's Blessing, this effect is enhanced: Normal Attack and Elemental Skill DMG is increased by 80%.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-10-16 04:00:00',
      end: '2024-11-16 04:00:00',
      color: '#4299E1',
      description: "After a character uses an Elemental Skill, said character's DEF increases by 60% for 9s.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-11-16 04:00:00',
      end: '2024-12-16 04:00:00',
      color: '#4299E1',
      description:
        "After the current active character's Charged Attack hits an opponent, said character's ATK increases by 20% for 8s. Max 4 stacks, each stack has an independent duration.",
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-08-01 04:00:00',
      end: '2024-09-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Hydro, Cryo, and Anemo. All characters in the party gain a 75% Dendro DMG Bonus',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-09-01 04:00:00',
      end: '2024-10-01 04:00:00',
      color: '#777df3',
      description: 'Required Elemental Types: Pyro, Hydro, and Dendro.',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-10-01 04:00:00',
      end: '2024-11-01 04:00:00',
      color: '#777df3',
      description: 'Required Elemental Types: Pyro, Electro, and Geo.',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-11-01 04:00:00',
      end: '2024-12-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Electro, and Dendro. Opening Characters: Klee, Xinyan, Yae Miko, Dori, Tighnari, and Kirara',
    },
  ],
  [
    {
      name: 'Battle Pass - Primal Fire',
      pos: '0% 45%',
      image: 'Primal Fire.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-10-07 03:59:59',
      color: '#b2f2eb',
      url: 'https://www.hoyolab.com/article/32490814',
      timezoneDependent: true,
    },
    {
      name: 'Battle Pass - Sabzeruz',
      pos: '0% 45%',
      image: 'Sabzeruz.png',
      start: '2024-10-09 06:00:00',
      end: '2024-11-18 03:59:59',
      color: '#dcebbe',
      url: 'https://www.hoyolab.com/article/34010692',
      timezoneDependent: true,
    },
  ],
];
